@import '../abstracts/variables';
@import '../abstracts/mixins';

.maturity-label--concept {
    background-color: $concept-light;
    color: $concept-dark;
    @include standard-label();
}

.maturity-label--poc {
    @include standard-label();
    background-color: $poc-light;
    color: $poc-dark;
}

.maturity-label--mlp {
    @include standard-label();
    background-color: $mlp-dark;
    color: $simple-white;
}

.maturity-label--ssp {
    @include standard-label();
    background-color: $ssp-dark;
    color: $simple-white;
}

.maturity-label--eol {
    @include standard-label();
    background-color: $eol-dark;
    color: $simple-white;
}