@import '../abstracts/variables';
@import '../abstracts/mixins';

.wifi-bars {
    display: flex;
    flex-direction: row;
    gap: 2px;

    &__bar {
        width: 30px;
        height: 26px;
        cursor: pointer;

        > div {
            background-color: $cimpress-grey;
            width: 100%;
            height: 100%;
            transition: background-color 0.8s;
        }

        &-selected {
            background-color: $cimpress-success !important;
        }
    }

    &__bar:nth-child(1) {
        > div {
            clip-path: polygon(0 100%, 100% 80%, 100% 100%, 0 100%);
        }
    }

    &__bar:nth-child(2) {
        > div {
            clip-path: polygon(0 78%, 100% 60%, 100% 100%, 0 100%);
        }
    }

    &__bar:nth-child(3) {
        > div {
            clip-path: polygon(0 58%, 100% 40%, 100% 100%, 0 100%);
        }
    }

    &__bar:nth-child(4) {
        > div {
            clip-path: polygon(0 38%, 100% 20%, 100% 100%, 0 100%);
        }
    }

    &__bar:nth-child(5) {
        > div {
            clip-path: polygon(0 18%, 100% 0%, 100% 100%, 0 100%);
        }
    }
}