@import '../abstracts/variables';
@import '../abstracts/mixins';

.summary__product-team {
    &__members {
        padding: $minimal-space $small-space $small-space $small-space;
        @include standard-border-bottom();
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        line-height: 15px;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                height: 32px;
                width: 32px;
                border-radius: 50%;
            }

            > span {
                display: flex;
                flex-direction: column;
                margin-left: 1rem;


                span:first-child {
                    font-size: $font-size-normal;
                    text-decoration: none;
                    color: $cimpress-cobalt;

                    > a, a:link {
                        color: $cimpress-cobalt;
                    }
                }

                span:last-child {
                    color: $cimpress-slate;
                    font-size: $font-size-label;
                    font-weight: 400;
                }

            }
        }
    }

    &__support {
        padding: $small-space $small-space $small-space $small-space;
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        font-size: 1.4rem;


        > span {
            color: $cimpress-table-header;
        }

        > div {
            color: $href-color;
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }
    }
}
