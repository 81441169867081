@import '../abstracts/variables';
@import '../abstracts/mixins';

.dp-dropdown {
    position: relative;
    z-index: 10;
    height: 100%;

    &__menu {
        position: absolute;
        list-style-type: none;
        margin: 0 0 0 2rem;
        padding: 0;
        border: 1px solid $cimpress-grey;

        li {
            margin: 0;
            background-color: white;
        }

        li:hover {
            background-color: $cimpress-light-grey;
        }

        li {
            button {
                width: 100%;
                height: 100%;
                text-align: left;
                background: none;
                color: inherit;
                border: none;
                padding: 1rem;
                margin: 0;
                font: inherit;
                cursor: pointer;
            }
        }

    }
}