.services-and-tools .services-and-tools-icon {
    border-radius: 50%;
    display: inline-block;
    padding: 42px;
}

#root {
    // padding-bottom: 60pt;
    background-color: white;
    min-height: 100vh;
}

.pagination {
    margin-top: 0px !important;
}

.centerText {
    text-align: center;
}

.CodeMirror, .CodeMirror-scroll {
	min-height: 160px !important;
}