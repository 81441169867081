@import '../abstracts/variables';
@import '../abstracts/mixins';

.discovery-container {
    @include main-container(); // The cimpress navbar creates an extra empty space at the bottom. We compensate.
}

.section-jumbo {
    position: relative;
    display: block;
    padding: $medium-space 0 2rem 0;

    &__container {
        height: 22rem;
        width: 100%;
        overflow-x: clip;
    }

    &--left {
        position: absolute;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-55%);
    }

    &--right {
        position: absolute;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-44.5%);
    }

    &__top-left-addon {
        position: absolute;
        top: $medium-space;
        left: $medium-space;
        z-index: 2;
    }

    &__bottom {
        position: absolute;
        top: 90%;
        left: 70%;
        transform: translateX(-50%);

        > span {
            color: $cimpress-grey;
            font-size: $font-size-normal;
        }
    }

    &__content {
        padding: 0 2rem;
        width: 70rem;
        position: inherit;
        margin: 0 auto;
        z-index: 3;
    }

    &__welcome {
        padding: 0 0.4rem 0.8rem 0.4rem;
        font-weight: $font-weight-normal;

        h2 {
            font-family: inherit;
            color: $cimpress-cobalt;
            margin: 0 0 1rem 0 !important;
        }

        p {
            color: $cimpress-cobalt;
            line-height: 20px;
        }
    }
}

.discovery__results {
    padding-left: $medium-space;
    padding-right: $medium-space;
    margin: $medium-space 0;
}

.search-bar {
    display: flex;
    margin-bottom: $small-space;
    width: 98%;

    small {
        display: inline-block;
        font-size: 1rem;
        padding: 0;
    }

    &__account-button {
        padding: $small-space;
        height: 40px;
        border: 1px solid $cimpress-grey;
        color: $simple-white;
        background-color: $cimpress-ocean-dark;
        border-radius: 20px 0 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 20rem;

        svg {
            height: 10px !important;
        }
    }

    &__search-input {
        width: 100%;
        padding-left: $small-space;
        border: 1px solid $cimpress-grey;
        border-left: 0;
        border-radius: 0 20px 20px 0;
        outline: none;
    }
}

.filters-bar {
    display: flex;
    gap: 1.8rem;
    z-index: 100;

    > label {
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        width: auto;

        > select {
            border: 0;
            background-color: $cimpress-light-grey;
            color: $cimpress-ocean-dark;
            border-radius: 20px;
            padding: 0.5rem 1rem;
            border-right: 1rem solid transparent;
            outline: none;
        }
    }

    &__analytical-btn {
        border: 0;
        background-color: $cimpress-light-grey;
        color: $cimpress-ocean-dark;
        border-radius: 20px;
        padding: 0.5rem 1rem 0.5rem 1.2rem;
        border-right: 1rem solid transparent;
        outline: none;
        height: 100%;
        width: 18rem;
        display: flex;
        justify-content: space-between;

        > span {
            padding-top: 0.1rem;
        }

        svg {
            height: 9.4px !important;
        }

        &__option {
            display: flex;
            gap: 0.4rem;
            align-items: center;
            padding: 0.28rem;
            width: 16rem;
        }
    }
}
