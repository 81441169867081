@import '../abstracts/variables';
@import '../abstracts/mixins';

.center {
    display: flex;
    justify-content: center;
}

.medium-bottom {
    margin-bottom: $medium-space;
}

.medium-top {
    margin-top: $medium-space;
}

.small-bottom {
    margin-bottom: $small-space;
}

.empty-message {
    font-size: $font-size-normal;
}

.dp-subtitle {
    font-size: $font-size-normal;
    line-height: 16px;
    font-weight: $font-weight-semibold;
    color: $cimpress-table-header;
}

.cimpress-modal-fix {
    .modal-body {
        overflow-y: visible !important;
    }
}

.editor-label {
    color: $cimpress-slate;
}