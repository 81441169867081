@import '../abstracts/variables';
@import '../abstracts/mixins';

.dp-card-discovery {
    @include standard-dp-card();
    border: 0.5mm solid $cimpress-grey;

    &:hover {
        box-shadow: 0px 8px 12px 2px rgba(0, 0, 0, 0.25);
        border: 0.5mm solid $cimpress-ocean-dark;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: $small-space;
        color: $cimpress-ocean-dark;
        font-weight: $font-weight-semibold;
        font-size: $font-size-big;
        letter-spacing: -0.6px;
        gap: 0.4rem;

        span {
            display: inline-block;
        }

        > span:first-child {
            height: 2rem;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__body {
        padding: 0 $small-space $minimal-space $small-space;
        font-size: $font-size-normal;
        height: 17.4rem;

        > p {
            line-height: 19px;
            height: 7.4rem;
            overflow: hidden;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;

        &__info {
            border-top: 0.5mm solid $cimpress-grey;
            height: 4.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $minimal-space $small-space;
        }

        &__author {
            padding: $minimal-space $small-space;
            color: $simple-black;

            > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                }

                span {
                    display: flex;
                    flex-direction: column;
                    margin-left: 0.5rem;

                    span:first-child {
                        font-size: $font-size-medium;
                        color: $cimpress-cobalt;
                        font-weight: $font-weight-semibold;
                    }

                    span:last-child {
                        color: $cimpress-slate;
                        font-size: $font-size-normal;
                        font-weight: 400;
                    }

                }
            }
        }

        &__ports {
            display: flex;
            flex-direction: row;
            gap: 0.4rem;
        }
    }
}
