@import '../abstracts/variables';
@import '../abstracts/mixins';

.summary-container {
    @include main-container; // The cimpress navbar creates an extra empty space at the bottom. We compensate.
    background-color: $cimpress-light-grey;
    padding: 2rem 2rem;
    min-height: 100vh
}

.summary {

    &__breadcrumbs {
        font-size: 1rem;
        line-height: 10px;
        font-weight: 400;
        color: $cimpress-darker-grey;
    }

    &__card__header {
        font-size: $font-size-big;
        padding: $small-space $minimal-space;
        display: flex;
        flex-direction: row;

        &__icon {
            @include summary-header-icon;
        }

        &__text {
            display: inline-block;
            padding: 0.4rem;
            margin-left: 0.4rem;
        }
     }

    &__title {
        display: flex;
        height: 5rem;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
        margin: $medium-space 0;
        padding: $minimal-space 0;

        &__labels {
            display: flex;
            align-items: center;
        }

        &__text {
            font-size: $font-size-bigger;
        }

        &__stars {
            display: flex;
            align-items: center;
            padding-top: 0.5rem; // small adjustment
        }

        &__edit {
            margin-left: auto;
        }
    }
}

.summary-tiny-mce-content-container{
    iframe {
        max-width: 100%;
    }
}