.data-product-node {
    border: 1px solid #bababa;
    border-radius: 6pt;
    margin-bottom: 6pt;
}

.data-product-node-title {
    border-bottom: 1px solid #bababa;
    padding: 6pt;
}

.data-product-node-content {
    padding: 6pt;
}
