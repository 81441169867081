@import '../abstracts/variables';
@import '../abstracts/mixins';

.icon-looker {
    @include span-icon($cimpress-success);
}

.icon-looker-medium {
    @include span-icon($cimpress-success, 20px, 0.8rem);
}

.icon-dataset {
    @include span-icon($cimpress-info);
}

.icon-dataset-medium {
    @include span-icon($cimpress-info, 20px, 0.8rem);
}
