@import "../abstracts/variables";
@import "../abstracts/mixins";

.summary__feedback {

    &__content {
        padding: $minimal-space $medium-space $medium-space $medium-space;
        font-size: $font-size-normal;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        p {
            margin: 0;
        }
    }

    &__anonymous {
        display: flex;
        gap: 1.2rem;
        align-items: center;
        svg {
            color: $cimpress-ocean-dark;
            height: 15px !important;
        }
    }

    &__domain {
        margin-bottom: -15px;
    }

    &__usefulness, &__uniqueness {
        display: grid;
        color: $cimpress-slate;
        grid-template-columns: 22% 30% auto;
        gap: 6px;

        > div {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }

        > div:last-child {
            font-size: $font-size-small;
            align-items: center;
        }

    }

    &__additional {
        > textarea {
            width: 100%;
            padding: 0.8rem 1.6rem;
            outline: none;
            border: 1.5px solid $cimpress-grey;
        }
    }

    &__submit {
        display: flex;
        justify-content: space-between;
    }
}
