@import '../abstracts/variables';

.dp-stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;

    &__star {
        display: inline-block;
        color: $cimpress-grey;

        svg {
            height: 16px !important;
        }
    }
}
