.alert-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 3.6px;
    color: $cimpress-slate;
    font-size: 1rem;
    line-height: 12px;

    svg {
        align-self: auto;
        min-width: 18px;
    }
}