@import '../abstracts/variables';
@import '../abstracts/mixins';

.dp-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;

    input[type=checkbox],
    input[type=checkbox]:checked,
    input[type=checkbox]:hover {
        accent-color: $cimpress-ocean-dark;
        margin: 0;
    }

    label {
        margin: 0;
        font-weight: $font-weight-normal;
    }
}