@media only screen and (min-width: 768px) {
    .summary-container {
        padding: 2rem 4rem;
    }
}

@media only screen and (min-width: 889px) {
    .summary-container {
        padding: 2rem 6rem;
    }
}

@media only screen and (min-width: 1200px) {
    .summary-container {
        padding: 2rem 12rem;
    }
}

@media only screen and (min-width: 1400px) {
    .summary-container {
        padding: 2rem 16rem;
    }
}

@media only screen and (min-width: 1600px) {
    .summary-container {
        padding: 2rem 20rem;
    }
}


