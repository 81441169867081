@import '../abstracts/variables';

.summary__description {
    &__content {
        font-size: $font-size-normal;
        word-wrap: break-word;
        line-height: 18px;
        ol {
            line-height: inherit;
        }
        ul {
            line-height: inherit;
        }
        padding: $minimal-space $medium-space $medium-space $medium-space;
    }
}