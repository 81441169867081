@import "../abstracts/variables";
@import "../abstracts/mixins";

.summary__custom-fields {

  &__items {
    padding: $minimal-space $medium-space $medium-space $medium-space;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden;

    &__name {
      @include data-label();
    }

    &__value {
      font-size: $font-size-normal;
      word-wrap: break-word;
      line-height: 18px;
      ol {
        line-height: inherit;
      }
      ul {
        line-height: inherit;
      }
    }
  }
}
