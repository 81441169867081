@import '../abstracts/variables';

@keyframes preloader {
    100% { transform: scale(2); }
  }

.loader {
	background-color: $simple-white ;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: 100000;

    &__element {
        border-radius: 100%;
        border: 5px solid $cimpress-grey;
        margin: 10px;
    }

    &__element:nth-child(1) {
        animation: preloader .6s ease-in-out alternate infinite;
    }

    &__element:nth-child(2) {
        animation: preloader .6s ease-in-out alternate .2s infinite;
    }

    &__element:nth-child(3) {
        animation: preloader .6s ease-in-out alternate .4s infinite;
    }
}
