@import '../abstracts/variables';
@import '../abstracts/mixins';

.dp-label {
    color: $simple-white;
    border-radius: 11px;
    font-weight: $font-weight-semibold;
    font-size: $font-size-normal;
    letter-spacing: 0.6px;
    background-color: $cimpress-success;
    padding: 0 8px;
    word-break: keep-all;
}

.dp-label-success {
    @include dp-label($cimpress-success);
}

.dp-label-warning {
    @include dp-label($cimpress-warning);
}

.dp-label-info {
    @include dp-label($href-color)
}

.dp-label-info-thin {
    @include dp-label($href-color, $padding-y: 0rem)
}

.dp-label-published {
    @include dp-label-small($cimpress-success);
}

.dp-label-unpublished {
    @include dp-label-small($cimpress-red);
}