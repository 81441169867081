@import '../abstracts/variables';
@import '../abstracts/mixins';

.user-dataproducts__header {
    @include custom-section($padding-bottom: 0, $margin-y: $medium-space-plus);
    display: flex;
    height: 4.8rem;
    flex-direction: row;
    justify-content: space-between;

    &__left {
        display: flex;
        gap: 2.4rem;
        flex-direction: row;
        justify-content: flex-start;
    }

    &__right {
        display: flex;
        align-content: center;
    }
}

.user-dataproducts__results {
    @include custom-section();

    &__items {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        column-gap: 2rem;
        row-gap: 3rem;
    }
}