@import '../abstracts/variables';
@import '../abstracts/mixins';


@mixin pdwdataset-section() {
    padding: 1rem 1rem 2rem 1rem;
}

@mixin pdw-label() {
    font-size: $font-size-label;
    color: $cimpress-slate;
    margin-bottom: 0.4rem;
    line-height: normal;
}

@mixin pdw-normal-text() {
    font-size: $font-size-normal;
    color: $cimpress-cobalt;
    white-space: pre-wrap;
    font-weight: 400;
    line-height: 18px;
}

.pdwdataset {

    &__title {
        font-size: $font-size-bigger;
    }

    &__section-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    &__subtitle {
        font-size: 1.6rem;
        display: flex;
        flex-direction: row;
        font-weight: $font-weight-semibold;
        align-items: center;
        gap: 1.2rem;
    }

    &__slack-channels, &__owners-list {
        display: flex;
        flex-direction: column;
    }

    &__overview {
        @include pdwdataset-section();
        @include standard-border-bottom();
        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            list-style-type: none;
            row-gap: 1.2rem;
            padding-left: 0;

            li {
                display: flex;
                flex-direction: column;
                line-height: normal;
                margin: 0;
                padding: 0;

                span:first-child {
                    @include pdw-label();
                }

                span:last-child {
                    @include pdw-normal-text();
                }
            }
        }

        > div:last-child {
            > span {
                @include pdw-label();
            }
            > p {
                @include pdw-normal-text();
                line-height: 20px;
            }
        }
    }


    &__columns {
        @include pdwdataset-section();
        @include standard-border-bottom();

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            list-style-type: none;
            row-gap: 1.2rem;
            column-gap: 0.6rem;
            padding-left: 0;

            li {
                display: flex;
                flex-direction: column;
                line-height: normal;

                span:first-child {
                    @include pdw-label();
                }

                span:last-child {
                    @include pdw-normal-text();
                }
            }
        }
    }

    &__data-issues {
        @include pdwdataset-section();

        p {
            @include pdw-normal-text();
        }
    }
}
