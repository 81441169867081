$minimal-space: 0.6rem;
$small-space: 1.2rem;
$medium-space: 2.4rem;
$medium-space-plus: 3.2rem;
$large-space: 4.8rem;
$cimpress-slate: #8090A2;
$cimpress-cobalt: #3A414C;
$cimpress-grey: #c4cdd6;
$cimpress-light-grey: #eff3f5;
$cimpress-dark-grey: #777;
$cimpress-table-header: #697582;
$cimpress-darker-grey: #3A414C;
$cimpress-ocean-dark: #00738E;
$cimpress-btn-secondary: #92C8DA;
$cimpress-info: #41B5DC;
$cimpress-success: #35AD96;
$cimpress-warning: #FFA23F;
$cimpress-red: #F0563A;
$cimpress-border-color: #A0A4A6;
$cimpress-divider-color: #C4CDD6;

$simple-white: #fff;
$simple-black: #333;

$href-color: #0088a9;


$font-size-small: 0.95rem;
$font-size-normal: 1.2rem;
$font-size-label: 1.1rem;
$font-size-medium: 1.4rem;
$font-size-big: 1.6rem;
$font-size-bigger: 2.4rem;

$font-weight-normal: 500;
$font-weight-semibold: 600;

/** Data Product Status **/
$active-dark: #143C2B;
$active-light: #58A685;

$onhold-dark: #3F3102;
$onhold-light: #F5CD47;

$deprecated-dark: #4F1C16;
$deprecated-light: #FF9C8F;

/** Data Product Maturity **/
$concept-dark: #5B4500;
$concept-light: #F6C342;

$poc-light: #CCCCCC;
$poc-dark: #353535;

$mlp-dark: #4A6785;

$ssp-dark: #13892C;

$eol-dark: #D04437