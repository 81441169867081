@import '../abstracts/variables';
@import '../abstracts/mixins';

.summary__resources {
    &__content {
        padding: $minimal-space 0 0 0;
        line-height: 15px;

        > div {
            display: flex;
            flex-direction: row;
            padding: $small-space;

            div:first-child {
                display: flex;
                align-items: center;
                margin-right: 1rem;
            }

            div:last-child {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;

                span {
                    display: inline-block;
                }

                span:first-child {
                    font-size: $font-size-normal;
                    cursor: pointer;
                    color: $href-color;
                }

                span:last-child {
                    color: $cimpress-slate;
                    font-size: $font-size-label;
                    font-weight: 400;
                }
            }
        }

        > div:not(:last-child) {
            @include standard-border-bottom();
        }

        > div:first-child {
            padding: 0 $small-space $small-space $small-space;
        }
    }
}
