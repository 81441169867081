@import '../abstracts/variables';
@import '../abstracts/mixins';

@mixin dp-button() {
    padding: 0.9rem $medium-space;
    font-size: $font-size-medium;
    font-weight: $font-weight-semibold;
    border: 1px solid $href-color;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.dp-btn, .dp-btn:link {
    @include dp-button();
    color: $href-color;
    background: $simple-white;
    transition: background 0.5s;
}

.dp-btn:hover {
    text-decoration: none;
    background: rgba($color: $cimpress-btn-secondary, $alpha: 0.5);
}

.dp-btn:disabled {
    color: $simple-white;
    background-color: $cimpress-grey;
    border: 1px solid $cimpress-light-grey;
}

.dp-btn-outline, .dp-btn-outline:link {
    @include dp-button();
    color: $simple-white !important;
    background-color: $href-color;
    transition: background 0.5s;
}

.dp-btn-outline:hover {
    text-decoration: none;
    color: $simple-white;
    background: rgba($color: $href-color, $alpha: 0.8);
}

.dp-btn-outline:disabled {
    color: $simple-white;
    background-color: $cimpress-grey;
    border: 1px solid $cimpress-light-grey;
}