@import '../abstracts/variables';
@import '../abstracts/mixins';

.discovery__results {
    @include custom-section();

    &__items {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        column-gap: 2rem;
        row-gap: 3rem;
    }
}