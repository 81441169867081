@media only screen and (max-width: 1600px) {
    .discovery__results__items {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 900px) {
    .discovery__results__items {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 600px) {
    .discovery__results__items {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 900px) {
    .section-jumbo {
        &__content {
            width: 60rem;
        }
    }
}

@media only screen and (max-width: 768px) {
    .section-jumbo {
        &__content {
            width: 48rem;
        }
    }
}
