@import '../abstracts/variables';

.support-icon {
    padding-top: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 18px;
        width: 18px;
        fill: $href-color;
    }
}

