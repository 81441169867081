@import '../abstracts/variables';
@import '../abstracts/mixins';

.summary__layout {
    display: grid;
    column-gap: $small-space;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
        "left_side left_side right_side";

    &-left {
        grid-area: left_side;
        display: flex;
        flex-direction: column;
        gap: $small-space;

        &__information {
            display: grid;
            column-gap: $small-space;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: auto;
            grid-template-areas: "left_side right_side";

            &-left {
                display: flex;
                flex-direction: column;
                gap: $small-space;
            }

            &-right {
                display: flex;
                flex-direction: column;
                gap: $small-space;
            }
        }
    }

    &-right {
        grid-area: right_side;
        display: flex;
        flex-direction: column;
        gap: $small-space;
    }
}
