@import '../abstracts/variables';
@import '../abstracts/mixins';


.status-label--active {
    background-color: $active-dark;
    color: $active-light;
    @include standard-label();
}

.status-label--maintenance {
    background-color: $active-dark;
    color: $active-light;
    @include standard-label();
}

.status-label--onhold {
    background-color: $onhold-dark;
    color: $onhold-light;
    @include standard-label();
}

.status-label--deprecated {
    background-color: $deprecated-dark;
    color: $deprecated-light;
    @include standard-label();
}
