@import '../abstracts/variables';
@import '../abstracts/mixins';

.summary__details {
    &__content {
        padding: $minimal-space $medium-space $medium-space $medium-space;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;
        row-gap: 2rem;
        line-height: 15px;

        > div {
            display: flex;
            flex-direction: column;

            span:first-child {
                color: $cimpress-slate;
                font-size: $font-size-label;
            }

            span:last-child {
                color: $cimpress-cobalt;
                font-size: $font-size-normal;
            }
        }
    }

    &__documentation {
        grid-column-start: 1;
        grid-column-end: 3;
        &-list {
            ul {
                list-style: none;
                padding-left: 0;

                li {
                    line-height: 18px;
                }
            }
        }
    }
}
