@import "./variables";

@mixin main-container {
  margin-top: -15px;
  font-family: "Open Sans", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

@mixin standard-dp-card() {
  position: relative;
  background-color: $simple-white;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  transition: border 0.6s, box-shadow 0.6s;
  line-height: 18px;
  overflow-wrap: break-word;

  a,
  a:link {
    text-decoration: none;
  }
}

@mixin span-icon($background-color, $height: 16px, $padding: 0.6rem) {
  color: $simple-white;
  display: inline-block;
  padding: $padding;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color;

  svg {
    height: $height !important;
  }
}

@mixin standard-border-bottom {
  border-bottom: 1px solid $cimpress-divider-color;
}

@mixin summary-card {
  padding: $medium-space;
}

@mixin summary-header-icon {
  color: $cimpress-red;
  display: inline-block;
  padding: 0.4rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 20px !important;
  }
}

@mixin data-label {
  color: $cimpress-slate;
  font-size: $font-size-label;
  margin-bottom: 0.2rem;
}

@mixin standard-label {
  border-radius: 5px;
  padding: 0.1rem 0.6rem;
  font-weight: $font-weight-semibold;
  letter-spacing: 1px;
}

@mixin dp-label($background-color, $color: $simple-white, $padding-y: 0.25rem) {
  border-radius: 25px;
  font-size: $font-size-label;
  color: $color;
  padding: $padding-y 0.8rem;
  display: inline-block;
  border: 0.5mm solid $background-color;
  background-color: $background-color;
}

@mixin dp-label-small($background-color, $color: $simple-white) {
  border-radius: 2px;
  font-size: $font-size-small;
  color: $color;
  padding: 0.16rem 0.32rem;
  display: inline-block;
  border: 0.5mm solid $background-color;
  background-color: $background-color;
  line-height: 1.4rem;
  letter-spacing: -0.4px;
}

@mixin custom-section($padding-bottom: $medium-space, $margin-y: $medium-space) {
  padding-left: $medium-space;
  padding-right: $medium-space;
  padding-bottom: $padding-bottom;
  margin: $margin-y 0;
}
